const Moralis = require("moralis");

const connectionMoralis = () => {
    // Moralis.initialize("0sQBVJYs8kC7JLicuuvKTmFamL516wngYWmgCGvS");
    // Moralis.serverURL = 'https://frxqe9xsgbqp.moralishost.com:2053/server';
    // Moralis.initialize("eadRvwT9HzZJyt27wU6icdroGO4ZL1ttl9hxhLZM");
    // Moralis.serverURL = 'https://c1tu5wwansbq.moralishost.com:2053/server';
    // const serverUrl = "https://frxqe9xsgbqp.moralishost.com:2053/server"
    // const appId =  "0sQBVJYs8kC7JLicuuvKTmFamL516wngYWmgCGvS" 
    // Moralis.start({ serverUrl, appId});
}


module.exports = {connectionMoralis: connectionMoralis}