import React, { Component } from 'react';
import Moralis from "moralis";
import { Container, Row, Col, InputGroup, FormControl, Modal, Image, Figure, Navbar, Button, ToastContainer, Toast, Overlay, OverlayTrigger, Popover, Alert } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/base-style.css';
import '../../css/style.css';
import Bitcoinimg from '../../images/bitcoin.png';
import Tokeninfoicon from '../../images/token.png';
import { chainID, AppNetwork, serverUrl, appID as appId } from '../../config/main';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          accounts: null,
          email: null,
          createdAt: null,
          updatedAt: null,
          alldata: null,
          lottoList: [],
        }
    }

    componentDidMount() {
      this.getLottoList();
      this.interval = setInterval(() =>{this.getLottoList();}, 3000);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
      this.setState({
        accounts: null,
        email: null,
        createdAt: null,
        updatedAt: null,
        alldata: null,
        lottoList: [],
      })
    }

    userEdit = () => {
        if(this.props.account) {
          return (
            <>
            <Alert variant={'success'}>{this.props.account}</Alert>
            <Modal.Dialog>
              <Modal.Header closeButton>
                <Modal.Title>Edit Account</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">Email</InputGroup.Text>
                  <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" value={this.props.email} onChange={(e)=>{this.props.setStateValue('email',e.target.value);}} />
                </InputGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={()=>{this.props.setUserinfo('email',this.props.email);}}>Save Email</Button>
              </Modal.Footer>
            </Modal.Dialog>
            </>
          )
        }
    }

    userAccount = () => {
      if(this.props.account) {
          const userinf = Moralis.User.current();
          console.log(JSON.stringify(userinf))
          return userinf.get('accounts');
      }
    }

    userEmail = () => {
      if(this.props.account) {
          const userinf = Moralis.User.current();
          return userinf.get('email');
      }
    }

    userCreated = () => {
      if(this.props.account) {
          const userinf = Moralis.User.current();
          return userinf.get('created');
      }
    }

    userUpdated = () => {
      if(this.props.account) {
          const userinf = Moralis.User.current();
          return userinf.get('updated');
      }
    }

    accountDetails = () => {
      if(this.props.account) {
        return (
          <>
            Account: <strong>{this.userAccount()}</strong>
            <br/>
            Email: <strong>{this.userEmail()}</strong>
          </>
        );
      }
    };

    getTokens = async() => {
      if(this.props.account) {
        const options = { chain: AppNetwork, address: this.state.account }
        const balances = await Moralis.Web3API.account.getTokenBalances(options);
        console.log('The Account Balances', balances);
      }
    };

    transferERC20 = async() => {
      // sending 0.5 tokens with 18 decimals
      const options = { chain: AppNetwork, type: "erc20", amount: Moralis.Units.Token("5", "18"), receiver: "0x10070cec350c1Acb977E3De484Fb96896d26D6D8", contract_address: "0x7ba942045ae0f9ce7e360396c5da91d2f01a6cc7"}
      let result = await Moralis.transfer(options);
      console.log('Get ',result);
    };

    getTransactions = async() => {
      const options = { chain: AppNetwork, address: this.props.account, order: "desc", from_block: "0" };
      let result = await Moralis.Web3API.account.getTransactions(options);
      console.log('Transactions',result);
    };

    getTokenMetaData = async() => {
      //Get metadata for an array of tokens
      const options = { chain: AppNetwork, addresses: ["0x7ba942045ae0f9ce7e360396c5da91d2f01a6cc7"] };
      const tokenMetadata = await Moralis.Web3API.token.getTokenMetadata(options);
      console.log('Token metadata', tokenMetadata);
    };

    getLottoList = async() => {
      const LottoQ = Moralis.Object.extend('Lotto');
      const query = new Moralis.Query(LottoQ);
      console.log('Query', query);
      query.descending("ForDate");
      const results = await query.find();
      let lottoArry = [];
      results.forEach((element,index)=>{
          if(new Date(element.get('ForDate')).getTime()>=Date.now()) {
              lottoArry.push({
                  'MinTokens':element.get('MinTokens'),
                  'TokenContract':element.get('TokenContract'),
                  'TokenName':element.get('TokenName'),
                  'TokenSymbol':element.get('TokenSymbol'),
                  'TokenLogo':element.get('TokenLogo'),
                  'HoldWallet':element.get('HoldWallet'),
                  'ForDate':element.get('ForDate')
              });
          }
      })
      this.setState({lottoList:lottoArry});
  };

  LotoList = () => {
    if(this.state.lottoList) {
      const lottoList = this.state.lottoList;
      return (
        <tbody>
          { lottoList.map((element, index)=>{
            let formattedDate = new Date(element.ForDate).toLocaleString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            });
            return (
              <Row key={index} className="homepageltable">
                <tr className="trhomepage">
                  <td colSpan="2"><Image src={element.TokenLogo} alt={element.TokenName} className="tokenicons" /></td>
                </tr>
                <tr>
                  <td colSpan="2" className="centeralign">{element.TokenName} (<strong>{element.TokenSymbol}</strong>)</td>
                </tr>
                <tr>
                  <td colSpan="2" className="centeralign">{element.MinTokens}</td>
                </tr>
                <tr>
                  <td colSpan="2" className="centeralign">{formattedDate} (GMT-5)</td>
                </tr>
              </Row>
            )
          })}
        </tbody>
      );
    }
  };

    render() {
        return (
        <>
        <header className="ch-header-sec">
          <br/>
          <br/>
          <div className="container-fluid">
              <div className="row align-items-center">
                  <div className="col-lg-6 col-md-5 d-none d-md-block">
                      <div className="hero-banner-img pe-md-5">
                          <Image src={Bitcoinimg} alt="" />
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-7">
                      <div className="hero-banner-text pt-5">
                          {(this.props.account)?<div className="walletaddress"><span>Wallet:</span> {this.props.account}</div>:''}
                          <h2>Win Big on Crypto</h2>
                          <h1><span>with</span> CryptoLotto</h1>
                          <p>Bet your crypto and your desired amount. More your bet, more chances of winning.</p>
                          <div className="btn btn-success">
                              Know More
                              <i className="icofont-long-arrow-right"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <br/><br/>
        </header>
        <div className="token-info-sec">
          <div className="container">
              <div className="sec-title text-center aos-init aos-animate" data-aos="fade-up">
                  <h1 data-watermark="Token">
                      <span>Crypto</span> to Bet
                  </h1>
                  <p>Active crypto tokens to bet</p>
              </div>
              <div className="row">
                  <div className="col-lg-6">
                      <div className="">
                          <h3>Lottery Information on Cryptos</h3>
                          <div className="table-responsive">
                              <table className="table table-borderless token-info-table">
                                {this.LotoList()}
                              </table>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6 pb-4 pb-lg-0">
                      <div className="aos-init aos-animate" data-aos="zoom-in">
                          <Image src={Tokeninfoicon} alt="" />
                      </div>
                  </div>
              </div>
              <br/><br/><br/><br/>
          </div>
        </div>
        </>
        )
    }

};

export default Home;