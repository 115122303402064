import { Container, Row, Col, InputGroup, FormControl, Modal, Figure, Navbar, Nav, Button, Image, Overlay, OverlayTrigger, Popover, Alert } from 'react-bootstrap';
import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/base-style.css';
import './css/style.css';
// import Web3 from "web3";
// import web3connect from "web3connect";
// import Web3Modal from "web3modal";
import Moralis from "moralis";
// import Connect from "./components/connect/connect";
// import Metamaskimg from './images/metamask.png';
// import Walletconnecting from './images/walletconnect.png';
// // import Logo from './images/logo.jpg';
// import Logo from './images/wheel.svg';
import Home from './components/body/home';
import Gamble from './components/body/gamble';
import Footer from './components/footer/main';
import Account from './components/body/account';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navi from './components/header/nav';
// import WalletConnectClient from "@walletconnect/client";
// import { Walletconnect, Metamask, Disconnect } from './components/connect/connection';

// importing constants
import { chainID, AppNetwork, serverUrl, appID as appId } from './config/main';



class App extends Component {
  constructor(props) {
    super(props);
    this.state ={
      connected: false,
      walletType: null,
      walletstate: null,
      web3Modal: null,
      web3: null,
      provider: null,
      chainId: null,
      account: null,
      network: 'mainnet', // mainnet or testnet
      email: '',
      status: null,
      connectstatus: 'Connect',
      connect: true,
      addressbal: null,
    }
  }

  componentDidMount() {
    Moralis.start({ serverUrl, appId });
    this.checkConnection();
    this.interval = setInterval(() =>{this.checkConnection();}, 3000);
  }

  checkConnection = async() => {
    const user = await Moralis.User.current();
    // let web3 = await Moralis.enableWeb3()
    // let currentChain  = await web3.eth.net.getId();
    // console.log("Web3 connection",web3);
    // console.log("Current chain",currentChain);
    // console.log(user);
    // console.log(user.logIn());
    // // console.log(user.getSessionToken());
    // console.log(user.isCurrent());
    if(user) { this.setState({connectstatus:'Connected',account:user.get('ethAddress'),email:user.get('email')}) } else { this.setState({connectstatus:'Connect',account:null}) }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  connect = async(type) => {
    const self = this;
    if(type === 'meta') {
      Moralis.authenticate({signingMessage: "Meta Login"}).then(function(user){
        if(user){self.setState({status:'Wallet connected & signed.',connectstatus:'Connected',account:user.get('ethAddress')})}
      }).catch((error)=>{console.log('Error cancel metamask',error);self.setState({status:'Metamask connect cancelled.',account:null})});
    }
    if(type === 'walletconnect') {
      Moralis.authenticate({ provider: "walletconnect", chainId: chainID, signingMessage: "Walletconnect Login" }).then(function(user){
        if(user){self.setState({status:'Wallet connected & signed.',connectstatus:'Connected',account:user.get('ethAddress')})}
      }).catch((error)=>{console.log('Error cancel walletconnect',error);self.setState({status:'Walletconnect cancelled.',account:null})});
    }
  };

  disconnect = async() => {
    const ustatus = await Moralis.User.logOut();
    if(ustatus !== undefined) {
      this.setState({status:'User logged out.',connectstatus:'Connect',account:null});
    } else {
      this.setState({status:'No logged detected.',connectstatus:'Connect',account:null});
    }
  };

  userinfo = () => {
    if(this.state.account) {
    const userinf = Moralis.User.current();
    return JSON.stringify(userinf);
    }
  }

  setStateValue = (key,value) => {
    this.setState({[key]:value});
  };
  
  setUserinfo = async(key,value) => {
    const user = Moralis.User.current();
    // console.log('User info', user);
    if(user) {
      user.set(key,value);
      const saveor = await user.save();
      // console.log('User saved', saveor);
      this.setState({status:'User information updated.'});
    } else {
      // console.log('User Not Connected');
      this.setState({status:'User not connected.'});
    }
  };

  userInfo = () => {
    if(this.state.account) {
      return (
        <>
        <Alert variant={'success'}>{this.state.account}</Alert>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-sm">Email</InputGroup.Text>
              <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" value={this.state.email} onChange={(e)=>{this.setState({ email: e.target.value })}} />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={()=>{this.setUserinfo('email',this.state.email);}}>Save Email</Button>
          </Modal.Footer>
        </Modal.Dialog>
        </>
      )
    }
  }

  render() {
    return (
      <div className="gradient">
        <Navi connect={this.connect.bind(this)} disconnect={this.disconnect.bind(this)} connectstatus={this.state.connectstatus} account={this.state.account}/>
        <br/>
        <Routes>
          <Route path="/" element={<Home account={this.state.account} status={this.state.status} email={this.state.email} setUserinfo={this.setUserinfo.bind(this)} setStateValue={this.setStateValue.bind(this)} />} />
          <Route path="/gamble" element={(this.state.account)?<Gamble account={this.state.account} status={this.state.status} email={this.state.email} setUserinfo={this.setUserinfo.bind(this)} setStateValue={this.setStateValue.bind(this)} />:<Navigate to="/" />} />
          <Route path="/account" element={(this.state.account)?<Account account={this.state.account} status={this.state.status} setUserinfo={this.setUserinfo.bind(this)} setStateValue={this.setStateValue.bind(this)} />:<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    );
  }
}

export default App;
