import { Container, Row, Col, InputGroup, FormControl, Modal, Figure, Navbar, Button, Image, Overlay, OverlayTrigger, Popover, Alert } from 'react-bootstrap';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/style.css';
import '../../css/base-style.css';
// import Web3 from "web3";
// import web3connect from "web3connect";
// import Web3Modal from "web3modal";
// import Moralis from "moralis";
import Metamaskimg from '../../images/metamask.png';
import Walletconnecting from '../../images/walletconnect.png';
import Logo from '../../images/wheel.svg';
import Connect from '../connect/connect';
import { useLocation, Link } from 'react-router-dom'

class Navi extends Component {
  constructor(props) {
    super(props);
    this.state ={
      thepage: null
    }
  }

  componentDidMount() {
    
  }

  loginOut = () => {
    const overlayConnect = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Connect your wallet</Popover.Header>
        <Popover.Body>
        <Row>
          <Col>
            <Figure>
              <Figure.Image width={50} height={50} padding={10} margin={20} style={{cursor:'pointer'}} alt="Walletconnect" src={Walletconnecting} onClick={()=>{this.props.connect('walletconnect');}} />
            </Figure>
          </Col>
          <Col>
            <Figure>
              <Figure.Image width={50} height={50} padding={10} margin={20} style={{cursor:'pointer'}} alt="Metamask" src={Metamaskimg} onClick={()=>{this.props.connect('meta');}} />
            </Figure>
          </Col>
        </Row>
        </Popover.Body>
      </Popover>
    );
    if(!this.props.account) {
      return (
        <OverlayTrigger trigger="click" placement="bottom" variant="primary" overlay={overlayConnect}>
          <div className="btn btn-sky">{this.props.connectstatus}</div>
        </OverlayTrigger>
      )
    }
    if(this.props.account) {
      return (<Button className="disconnect" onClick={()=>{this.props.disconnect()}}>Disconnect</Button>);
    }
  }

  render() {
    return (
        <div className="container-fluid">
          <div className="bitcoin-banner-wrap">
              <header className="landing-header-area">
                <div className="ch-header landing-header-wrapper">
                    <Link to="/" className="h-logo pe-3">
                      <Image src={Logo} height="50" width="50" alt="Home"/>
                    </Link>
                    <div className="header-right">
                        <div className="h-menu-wrap d-none d-xl-block">
                            <ul className="h-menu">
                                <li><Link to="/" activeClassName="active">Home</Link></li>
                                {(this.props.account)?<li><Link to="/gamble" activeClassName="active">Gamble</Link></li>:''}
                                {(this.props.account)?<li><Link to="/account" activeClassName="active">Account</Link></li>:''}
                                <li><Link to="/about-us" activeClassName="active">About Us</Link></li>
                                <li><Link to="/faq" activeClassName="active">FAQ</Link></li>
                                <li><Link to="/contact" activeClassName="active">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="menu-trigger d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="h-btn d-none d-xl-block ms-xl-4">
                            <div className="short-code">
                                {this.loginOut()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-wrap d-xl-none">
                    <div className="menu-close">
                        <ul><i className="icofont-close-line"></i></ul>
                    </div>
                    <div className="mobile-menu"></div>
                    <div className="my-4">
                        <div className="short-code">
                            <Link to="#" className="btn btn-sky">{this.loginOut()}</Link>
                        </div>
                    </div>
                </div>
            </header>
          </div>
        </div>
    );
  }
}

export default Navi;