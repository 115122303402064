// Testnet

// const chainID = 97;

// const AppNetwork = 'bsc testnet';

// const serverUrl = "https://frxqe9xsgbqp.moralishost.com:2053/server";

// const appID = "0sQBVJYs8kC7JLicuuvKTmFamL516wngYWmgCGvS";

// Mainnet

const chainID = 56;

const AppNetwork = 'bsc';

const serverUrl = "https://c1tu5wwansbq.moralishost.com:2053/server";

const appID = "eadRvwT9HzZJyt27wU6icdroGO4ZL1ttl9hxhLZM";

module.exports = { chainID, AppNetwork, serverUrl, appID }