import React, { Component } from 'react';
import Moralis from "moralis";
import { Container, Row, Col, InputGroup, ProgressBar, Form, Image, FormControl, Modal, Figure, Navbar, Button, ListGroup, Overlay, OverlayTrigger, Popover, Alert, Toast, ToastContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Connect from '../connect/connect';
import { chainID, AppNetwork, serverUrl, appID as appId } from '../../config/main';


class Gamble extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newtokensPresent: null,
            lottoList: [],
            lottono: null,
            lottostatus: null,
            wallentHist: null,
            receiverBalance: null,
            address: '0x10070cec350c1Acb977E3De484Fb96896d26D6D8',
            addressbal: null,
            status: null,
            progressno: null,
            symbolselected: null,
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({
            newtokensPresent: null,
            lottoList: [],
            lottono: null,
            lottostatus: null,
            wallentHist: null,
            address: null,
            addressbal: null,
            status: null,
            progressno: null,
            symbolselected: null,
        })
    }

    async componentDidMount() {
        // let user = await Moralis.User.current();
        // let waddress = user.get('ethAddress');
        // this.setState({account:waddress});

        this.checkConnection();
        this.updateBalance();
        // (waddress)?this.getTokens():''
        // this.getLottoList();
        // this.walletHistory();

        this.interval = setInterval(() =>{
            this.checkConnection();
            // this.loginCheck();
            this.updateBalance();
            // this.liveUpdate();
            // this.getLottoList();
        }, 3000);
    }

    loginCheck = async() => {
        let user = await Moralis.User.current();
        console.log('loginCheck',user.get('ethAddress'));
        let waddress = user.get('ethAddress');
        this.setState({account:waddress});
    }

    // Checking login
    checkConnection = async() => {
        const user = await Moralis.User.current();
        (user)?this.getTokens():''
        this.getLottoList();
        this.walletHistory();
        console.log("Checking connection",user)
        if(user) { this.setState({connectstatus:'Connected',account:user.get('ethAddress'),email:user.get('email')}) } else { this.setState({connectstatus:'Connect',account:null}) }
    }

    updateBalance = async() => {
        const options = { chain: AppNetwork, address: this.state.account };
        const balances = await Moralis.Web3API.account.getTokenBalances(options);
        this.setState({addressbal: balances});
    }

    getTokens = () => {
        let user = Moralis.User.current();
        let waddress = user.get('ethAddress');
        console.log('Address',waddress)
        this.setState({account:waddress});
        if(this.props.account) {
            this.getLottoList();
            this.walletHistory();
        }
    };

    getLottoList = async() => {
        const LottoQ = Moralis.Object.extend('Lotto');
        const query = new Moralis.Query(LottoQ);
        query.descending("ForDate");
        const results = await query.find();
        let lottoArry = [];
        results.forEach((element,index)=>{
            if(new Date(element.get('ForDate')).getTime()>=Date.now()) {
                lottoArry.push({
                    'MinTokens':element.get('MinTokens'),
                    'TokenContract':element.get('TokenContract'),
                    'TokenName':element.get('TokenName'),
                    'TokenSymbol':element.get('TokenSymbol'),
                    'TokenLogo':element.get('TokenLogo'),
                    'HoldWallet':element.get('HoldWallet'),
                    'ForDate':element.get('ForDate')
                });
            }
        })
        this.setState({lottoList:lottoArry});
        const options = { chain: AppNetwork, address: this.props.account }
        const balances = await Moralis.Web3API.account.getTokenBalances(options);
        console.log('Balaces',balances)
        let newBalance = [];
        balances.forEach((element,i)=>{
            lottoArry.forEach((selement,j)=>{
                if(selement.TokenContract===element.token_address){
                    element.minToken = selement.MinTokens;
                    element.forDate = selement.ForDate.getTime();
                    newBalance.push(element);
                }
            });
        })
        console.log('Lotto List',this.state.lottoList);
        console.log('New Balance',newBalance);
        this.setState({newtokensPresent:newBalance});
    };

    tokenBalances = () => {
        if(this.state.newtokensPresent) {
            let newtokensPresent = this.state.newtokensPresent;
            return (
                <ListGroup>
                    { 
                        newtokensPresent.map((element, i) => {
                            return <ListGroup.Item key={i} className="find-item-box">
                                Name: {element.name}<br/>
                                Symbol: {element.symbol}<br/>
                                Thumbnail: {element.thumbnail}<br/>
                                Logo: {element.logo}<br/>
                                Decimals: {element.decimals}<br/>
                                Address: {element.token_address}<br/>
                                Your Balance: {(element.balance/Math.pow(10, element.decimals)).toFixed(5)}<br/>
                                MinToken: {(element.minToken)?element.minToken:'None'}<br/>
                                Date: {(element.forDate)?element.forDate:'None'}<br/>
                                {this.buyLotto(element.minToken,element.balance,element.token_address,element.name,element.decimals,element.forDate)}
                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
            );
        }
    };

    buytransferLotto = async(mint,balance,token_address,decimals,forDate) => {
        if(this.state.lottono) {

            this.setState({status:'processing',progressno:5});
            const total = mint*this.state.lottono;
            const totalBalance = Moralis.Units.Token(total, decimals);
            const thedate = new Date(forDate);
            const receiver = "0x10070cec350c1Acb977E3De484Fb96896d26D6D8";
            const options = { chain: 'bsc testnet', type: "erc20", amount: totalBalance, receiver: receiver, contract_address: token_address, for_date: thedate}
            let result = await Moralis.transfer(options).then(function(data){return data;}).catch((error)=>{console.log('Stop transfer',error);this.setState({status:'stopped',progressno:null});});

            this.setState({status:'processing',progressno:70});
            if(result===undefined) {
                this.setState({status:'stopped',progressno:null});
            } else if(result.blockHash){
            // Call Cloud fuction Save Lotto
                const params =  { thedate:thedate,blockHash:result.blockHash,account:this.props.account,token_address:token_address,total:total,transactionHash:result.transactionHash };
                const returnSave = await Moralis.Cloud.run("savetransferLotto", params);
                this.setState({lottostatus:returnSave.lottostatus,status:'completed',progressno:100});
            }
        } else {
            console.log('Lotto Number not set'.this.state.lottono);
        }
    };

    totalBet = (mint,balance,token_address,decimals,forDate,name) => {
        if(this.state.lottono && mint && balance && (this.state.symbolselected===name)) {
            if(this.state.lottono*mint<=(balance/Math.pow(10, decimals))) {
                return (
                <>
                    <Form.Label>Total Bet: {this.state.lottono*mint}</Form.Label><br/>
                    <Button onClick={()=>{this.buytransferLotto(mint,balance,token_address,decimals,forDate);}}>Buy {name} Lotto</Button>
                </>
                );
            }
        }
    }
     
    showProgress = (no) => {
        if(this.state.status === 'processing') {
            return (
            <>
            <center className="statusupdate">Processing</center>
            <div className="progressbar">
                <ProgressBar striped variant="warning" now={this.state.progressno} label={`${this.state.progressno}%`} />
            </div>
            </>
            );
        }
        if(this.state.status === 'completed') {
            return (
            <>
            <center className="statusupdate">Completed</center>
            <div className="progressbar">
                <ProgressBar striped variant="success" now={this.state.progressno} label={`${this.state.progressno}%`} />
            </div>
            </>
            );
        }
        if(this.state.status === 'stopped') {
            return (
            <>
            <center className="statusupdate">Cancelled</center>
            <div className="progressbar">
                <ProgressBar striped variant="danger" now={this.state.progressno} label={'Cancelled'} />
            </div>
            </>
            );
        }
    }

    buyLotto = (mint,balance,token_address,name,decimals,forDate) => {
        if(mint>balance) {
            return (<>Minimum balance not provided.</>);
        }
        if(mint) {
            return (
                <Form>
                    <Row>
                    <Form.Group controlId={name+'-lotto'} className="mb-3">
                        <Form.Label>Number of Bets</Form.Label>
                        <Form.Control type="number" onChange={(e)=>{this.setState({lottono:e.target.value,symbolselected:name});}}/>
                        {this.totalBet(mint,balance,token_address,decimals,forDate,name)}
                    </Form.Group>
                    </Row>
                </Form>
            );
        }

    };

    walletHistory = async() => {
        if(this.state.account) {
            const walletHist = Moralis.Object.extend('LottoTransfer');
            const query = new Moralis.Query(walletHist);
            query.equalTo("wallet", this.state.account);
            query.descending("createdAt");
            query.limit(20);
            const results = await query.find();
            let walletH = [];
            if(results.length > 0) {
                console.log('History of lotto purchase:',results.length)
                results.forEach((element, index)=>{
                    walletH.push(element);
                })
            }
            (walletH.length>0)?this.setState({wallentHist:walletH}):''
        }
    };

    walletHistList = () => {
        if(this.state.wallentHist) {
            return (
                <ListGroup>
                    {
                        this.state.wallentHist.map((element, index)=>{
                            if(Date.now()<element.attributes.for_date.getTime()) {
                                return (
                                    <>
                                    <ListGroup.Item key={index} as="li" active>
                                        Token Address: {element.attributes.token_address}<br/>
                                        Amount: {element.attributes.total_balance}<br/>
                                        Lotto Date: {(element.attributes.for_date)?new Date(element.attributes.for_date).toLocaleString("en-US", {timeZone: "America/New_York"}):''}
                                    </ListGroup.Item>
                                    <br/>
                                    </>
                                );
                            } else {
                                return (
                                <>
                                    <ListGroup.Item key={index} as="li" disabled>
                                        Token Address: {element.attributes.token_address}<br/>
                                        Amount: {element.attributes.total_balance}<br/>
                                        Lotto Date: {(element.attributes.for_date)?new Date(element.attributes.for_date).toLocaleString("en-US", {timeZone: "America/New_York"}):''}
                                    </ListGroup.Item>
                                    <br/>
                                </>
                                )
                            }
                        })
                    }
                </ListGroup>
            )
        }
    };

    LotoList = () => {
        if(this.state.lottoList) {
            const lottoList = this.state.lottoList;
            return (
                <ToastContainer>
                    { lottoList.map((element, index)=>{
                        return (
                                <Toast key={index}>
                                    <Toast.Header closeButton={false}>
                                    <img src={(element.TokenLogo)?element.TokenLogo:''} className="rounded me-2" alt={(element.TokenName)?element.TokenName:''} style={{width:30}} />
                                    <strong className="me-auto">{(element.TokenName)?element.TokenName:''}</strong>
                                    <small className="text-muted"><strong>{(element.TokenSymbol)?element.TokenSymbol:''}</strong></small>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <strong>Token Contract:</strong> {(element.TokenContract)?element.TokenContract:''}<br/>
                                        <strong>Minimum Coins:</strong> {(element.MinTokens)?element.MinTokens:''}<br/>
                                        <strong>Hold Wallet:</strong> {(element.HoldWallet)?element.HoldWallet:''}<br/>
                                        <strong>Date:</strong> {(element.ForDate)?new Date(element.ForDate).toLocaleString("en-US", {timeZone: "America/New_York"})+' (GMT-5)':''} 
                                    </Toast.Body>
                                </Toast>
                        )
                    })}
                </ToastContainer>
            );
        }
    };

    liveUpdate = async() => {
        if(this.state.account) {
        let querylh = new Moralis.Query('LottoTransfer');
        let subscriptionlh = await querylh.subscribe();
        await subscriptionlh.on('create', (object) => {
            this.setState({lottono:null}); this.walletHistory();
        });
        let queryl = new Moralis.Query('Lotto');
        let subscriptionl = await queryl.subscribe();
        subscriptionl.on('create', (object) => { console.log('Lotto created'); this.getLottoList(); });
        if(this.state.status === 'completed'){
            this.setState({status:null,progressno:null});
        } else if(this.state.status === 'stopped'){
            this.setState({status:null,progressno:null});
        }
        }
    }

    addressBalance = () => {
        if(this.state.addressbal) {
            const addressbal = this.state.addressbal;
            return (
            <ListGroup>
                {
                addressbal.map((element, index)=> {
                    console.log(element);
                    return (
                        <ListGroup.Item key={index}>
                            Name: {element.name} {element.symbol}<br/>
                            Balance: {element.balance/Math.pow(10, element.decimals)}<br/>
                            Contract: {element.token_address}
                        </ListGroup.Item>
                    )
                })
                }
            </ListGroup>
            )
        }
    };

    render() {
        return (
        <Container>
            <Row>
                <Col xs={6} md={6} lg={6}>
                {this.showProgress()}
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title>Find Game</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.tokenBalances()}
                        </Modal.Body>
                        <Modal.Footer>
                            
                        </Modal.Footer>
                    </Modal.Dialog>
                </Col>
                <Col xs={6} md={6} lg={6}>
                    {(this.props.account)?<div className="walletaddress"><span>Wallet:</span> {this.props.account}</div>:''}
                    <Modal.Dialog>
                        {this.addressBalance()}
                        <Modal.Header>
                            <Modal.Title>Lotto Available</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.LotoList()}
                        </Modal.Body>
                        <Modal.Header>
                            <Modal.Title>Purchase History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.walletHistList()}
                        </Modal.Body>
                    </Modal.Dialog>
                </Col>
                <Col xs={12} md={12} lg={12}>
                    {(this.state.lottostatus)?'Status:'+this.state.lottostatus:''}<br/>
                    <Button onClick={()=>{this.getTokens()}}>Buy Lotto</Button>
                </Col>
            </Row>
        </Container>
        )
    }

};

export default Gamble;