import React, { Component } from 'react';
import { Container, Row, Col, InputGroup, Form, Image, FormControl, Modal, Figure, Navbar, Button, ListGroup, Overlay, OverlayTrigger, Popover, Alert, Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/base-style.css';
import '../../css/style.css';
import MailchimpSubscribe from "react-mailchimp-subscribe";


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <footer className="bg-overlay-secondary-gradient backgroundimgfooter">
                <div className="footer-sec pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6 py-4">
                                <div className="footer-widget">
                                    <div className="footer-logo mb-4">
                                        <Image src="assets/img/logo-white.svg" alt="" />
                                    </div>
                                    <p>Follow and connect with us on our social media and channels for latest news and updates. We value your participation.</p>
                                    <div className="social-icons">
                                        <i className="icofont-facebook"></i>
                                        <i className="icofont-twitter"></i>
                                        <i className="icofont-instagram"></i>
                                        <i className="icofont-youtube-play"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 py-4">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Sitemap</h4>
                                    <ul className="footer-widget-menu column-2">
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/token">Token</Link></li>
                                        <li><Link to="/statistic">Statistic</Link></li>
                                        <li><Link to="/roadmap">Roadmap</Link></li>
                                        <li><Link to="/team">Team</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        <li><Link to="/error">Error</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-sm-6 py-4">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Link</h4>
                                    <ul className="footer-widget-menu mb-4">
                                        <li>Help & FAQ</li>
                                        <li>Terms & Condition</li>
                                        <li>Privecy Policy</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6 py-4">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Newsletter</h4>
                                    <p>Subscribe our newsletter for update</p>
                                    <div className="newsletter-widget mb-4">
                                        <MailchimpSubscribe 
                                            url="https://win7app.us15.list-manage.com/subscribe/post?u=8bcc6312c641549ffcf35abf5&amp;id=9f7ed94497"
                                            messages={{
                                                  sending: "Sending...",
                                                  success: "Thank you for subscribing!",
                                                  error: "An unexpected internal error has occurred.",
                                                  empty: "You must write an e-mail.",
                                                  duplicate: "Too many subscribe attempts for this email address",
                                                  button: "Subscribe!"
                                            }}
                                        />
                                    </div>
                                    <script src="https://apps.elfsight.com/p/platform.js" defer></script>
                                    <div className="elfsight-app-79a508e4-9f4e-4478-a9e0-4ce71ce115cd"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-sec bg-primary">
                    <div className="container">
                        <div className="copyright-inner text-center d-block">
                            <p>Copyright © {new Date().getFullYear()} CryptoLotto All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;