import React, { Component } from 'react';
import Moralis from "moralis";
import { Row, Col, InputGroup, FormControl, Modal, Button, Table, thead, tbody, tr, td, th } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/base-style.css';
import '../../css/style.css';
import Connect from '../connect/connect';
import { chainID, AppNetwork, serverUrl, appID as appId } from '../../config/main';


class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useremail: null,
            balances: [],
            email: null,
        };
    }

    componentDidMount() {
        Connect.connectionMoralis();
        this.getTokens();
        let user = Moralis.User.current();
        this.setState({useremail: user.get('email')});
        this.interval = setInterval(() =>{this.getTokens();}, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({
            useremail: null,
            balances: [],
            email: null,
        })
    }

    loginOut = () => {
        if(!this.props.account) {
            return (
                <div>Not Logged in.</div>
            )
        }
        if(this.props.account) {
            return this.userEdit();
        }
    }

    accountDetails = () => {
        if(this.props.account) {
          return (
            <>
            <tr>
                <td>Wallet</td>
                <td><strong>{this.props.account}</strong></td>
            </tr>
            <tr>
                <td>Email</td>
                <td><strong>{this.state.useremail}</strong></td>
            </tr>
            </>
          );
        }
    };

    tokenlists = () => {
        const balances = this.state.balances;
        if(balances.length>0) {
            return (
                <>
                    {balances.map((element, index)=>{
                        return (
                            <table className="cryptbalance" key={index}>
                            <tbody>
                            <tr>
                                <td className="leftone">Name</td>
                                <td className="rightone">{element.name} (<strong>{element.symbol}</strong>)</td>
                            </tr>
                            <tr>
                                <td className="leftone">Amount</td>
                                <td className="rightone">{element.balance/Math.pow(10, element.decimals)}</td>
                            </tr>
                            </tbody>
                            </table>
                        );
                    })}
                </>
            );
        } else {
            return (
                <table className="cryptbalance">
                    <tbody>
                        <tr>
                            <td className="leftone">No Tokens available...</td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    };

    getTokens = async() => {
        if(this.props.account) {
          const options = { chain: AppNetwork, address: this.state.account }
          const balances = await Moralis.Web3API.account.getTokenBalances(options);
          console.log('Balances',balances);
          const user = Moralis.User.current();
          if(user)this.setState({useremail: user.get('email'),balances: balances});
        }
    };

    userEdit = () => {
        if(this.props.account) {
          return (
            <Row className="accountinfobg">
                <Col xs={8} md={8} lg={8} className="token-info-sec">
                    <div className="sec-title text-center aos-init aos-animate" data-aos="fade-up">
                        <h1 data-watermark="Token"><span>Account</span> Info</h1>
                        <div className="table-responsive">
                            <table className="table table-borderless token-info-table">
                                <tbody>
                                    {this.accountDetails()}
                                </tbody>
                            </table>
                        </div>
                        <h2 data-watermark="Token"><span>Crypto</span> Balances</h2>
                        <div className="table-responsive">
                            {this.tokenlists()}
                        </div>
                    </div>
                    {this.props.status}
                </Col>
                <Col xs={4} md={4} lg={4}>
                    <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-sm">Email</InputGroup.Text>
                        <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e)=>{this.setState({email:e.target.value});}} />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={()=>{this.props.setUserinfo('email',this.state.email);}}>Save Email</Button>
                    </Modal.Footer>
                    </Modal.Dialog>
                </Col>
            </Row>
          )
        }
    }

    render() {
        return (
            <div>
                {this.loginOut()}
            </div>
        )
    }
}

export default Account;